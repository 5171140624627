<template>
  <div class="login-page">
    <div class="all-title">{{ step == '1' ? '换绑定手机号' : '绑定手机号' }}</div>
    <div class="common-back" @click="backAction">
      <img
        src="https://artimg2.artart.cn/image/20220428/c2e0865b1ace1cb7c56e7427381c234e.png"
        alt=""
      >
    </div>
    <Login
      ref="LoginEL"
      :check="step"
      :isBind="isBind"
      @oneOk="oneOkAction"
      @twoOk="twoOkAction"
    />
  </div>
</template>

<script>
import Login from "@/components/Login/Login";
import { ref, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    Login,
  },
  setup() {
    const step = ref("1");
    const LoginEL = ref(null);
    const route = useRoute();
    const isBind = ref(false);
    const router = useRouter();

    onBeforeMount(() => {
      if (route.query.type) {
        step.value = route.query.type;
        isBind.value = true;
      }
    });

    const oneOkAction = () => {
      LoginEL.value.resetForm();
      step.value = "2";
    };
    const twoOkAction = () => {};

    const backAction = () => {
      if (window.history.length <= 1) {
        router.replace("/");
      } else {
        router.back();
      }
    }
    return {
      step,
      LoginEL,
      isBind,

      oneOkAction,
      twoOkAction,
      backAction
    };
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  background: #1e1e1e;
  height: 100vh;
}
</style>